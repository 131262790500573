// store.js 파일

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let interval = null; // interval 변수를 정의합니다.

const store = new Vuex.Store({
  state: {
    activePetIndex: 0,
    timer: parseInt(localStorage.getItem("timer")) || 0, // 로컬 저장소에서 타이머 값을 가져오고, 없을 경우 0으로 설정합니다.
  },
  mutations: {
    setActivePetIndex(state, index) {
      state.activePetIndex = index;
    },
    incrementTimer(state) {
      state.timer++;
      localStorage.setItem("timer", state.timer.toString()); // 타이머 값을 로컬 저장소에 저장합니다.
    },
    resetTimer(state) {
      state.timer = 0;
      localStorage.removeItem("timer"); // 로컬 저장소에 저장된 타이머 값을 제거합니다.
      localStorage.removeItem("calorie"); // 로컬 저장소에 저장된 타이머 값을 제거합니다.
      localStorage.removeItem("distance"); // 로컬 저장소에 저장된 타이머 값을 제거합니다.
    },
    setTimer(state, value) {
      state.timer = value;
    },
  },
  actions: {
    startTimer({ commit, state }) {
      clearInterval(interval);
      interval = setInterval(() => {
        commit("incrementTimer");
      }, 1000);
    },
    stopTimer({ commit }) {
      clearInterval(interval); // interval 변수를 사용하여 인터벌을 정지시킵니다.
      interval = null;
      commit("resetTimer");
    },
  },
  getters: {
    formattedTimer(state) {
      const hours = Math.floor(state.timer / 3600);
      const minutes = Math.floor((state.timer % 3600) / 60);
      const seconds = state.timer % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
  },
});

// 애플리케이션 로드 시 타이머를 이어서 실행합니다.

export default store;
