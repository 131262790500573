import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginIndex",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/findID",
    name: "FindID",
    component: () => import("@/views/login/find_id.vue"),
  },
  {
    path: "/findPW",
    name: "FindPW",
    component: () => import("@/views/login/find_pw.vue"),
  },
  {
    path: "/test",
    name: "TestLayout",
    component: () => import("@/components/Layout/testLayout.vue"),
    children: [
      {
        path: "",
        name: "Testindex",
        meta: {
          title: "타이틀제목",
          logo: "health-pet-img.png",
        },
        component: () => import("@/views/test.vue"),
      },
    ],
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/components/Layout/footerLayout.vue"),
    children: [
      {
        path: "/",
        name: "MainIndex",
        component: () => import("@/views/main/index.vue"),
      },
      {
        path: "/main/splash",
        name: "splash",
        component: () => import("@/views/main/splash.vue"),
      },
      {
        path: "/mypage/pet-registration",
        name: "PetRegi",
        meta: "",
        component: () => import("@/views/mypage/pet-registration.vue"),
      },
      {
        path: "/mypage/pet-edit/:id",
        name: "PetEdit",
        meta: "",
        component: () => import("@/views/mypage/pet-edit.vue"),
      },
      {
        path: "/mypage/mypage",
        name: "MyPageIndex",
        meta: "",
        component: () => import("@/views/mypage/mypage.vue"),
      },
      {
        path: "/mypage/password_update",
        name: "PassWordUpdate",
        meta: "",
        component: () => import("@/views/mypage/password.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/components/Layout/registerLayout.vue"),
    children: [
      {
        path: "/login",
        name: "LoginIndex",
        component: () => import("@/views/login/index.vue"),
      },
      {
        path: "/login/register",
        name: "Register",
        component: () => import("@/views/login/register.vue"),
      },
      {
        path: "/login/register2",
        name: "Register2",
        component: () => import("@/views/login/register2.vue"),
      },
      {
        path: "/login/register3",
        name: "Register3",
        component: () => import("@/views/login/register3.vue"),
      },
    ],
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/mypage/mypage-no-pet",
        name: "MyPageNoPet",
        component: () => import("@/views/mypage/mypage-no-pet.vue"),
      },

      {
        path: "/mypage/faq",
        name: "FAQ",
        meta: {
          title: "자주묻는 질문",
          logo: "faq-icon.png",
        },
        component: () => import("@/views/mypage/faq.vue"),
      },
      {
        path: "/mypage/notify-setting",
        name: "NotifySetting",
        meta: {
          title: "건강",
          logo: "health-icon.png",
        },
        component: () => import("@/views/mypage/notify-setting.vue"),
      },

      {
        path: "/mypage/notice",
        name: "Notice",
        meta: {
          title: "공지사항",
          logo: "notice.svg",
        },
        component: () => import("@/views/mypage/notice.vue"),
      },

      {
        path: "/mypage/noticeView/:id",
        name: "NoticeView",
        meta: {
          title: "공지사항",
          logo: "notice.svg",
        },
        component: () => import("@/views/mypage/notice-view.vue"),
      },

      {
        path: "/mypage/alarms",
        name: "Alarms",
        meta: {
          title: "알림 메시지",
          logo: "notice.svg",
        },
        component: () => import("@/views/mypage/alarm.vue"),
      },
    ],
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "ScheduleIndex",
        meta: {
          title: "일정",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/index.vue"),
      },
      {
        path: "/schedule/add_schedule",
        name: "AddSchedule",
        meta: {
          title: "일정",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/add_schedule.vue"),
      },
      {
        path: "/schedule/update_schedule",
        name: "UpdateSchedule",
        meta: {
          title: "일정",
          logo: "schedule-icon.png",
        },
        component: () => import("@/views/schedule/update_schedule.vue"),
      },
    ],
  },
  {
    path: "/walk",
    name: "Walk",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "WalkIndex",
        meta: {
          title: "산책",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/index.vue"),
      },
      {
        path: "/walk/map",
        name: "walkmap",
        meta: {
          title: "산책",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/walkmap.vue"),
      },
      {
        path: "/walk/hunt",
        name: "huntingmap",
        meta: {
          title: "사냥",
          logo: "walk-icon.png",
        },
        component: () => import("@/views/walk/hunt.vue"),
      },
    ],
  },
  {
    path: "/health",
    name: "Health",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "HealthIndex",
        meta: {
          title: "건강",
          logo: "health-icon.png",
        },
        component: () => import("@/views/health/index.vue"),
      },
    ],
  },
  {
    path: "/device",
    name: "Device",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/",
        name: "deviceAdd",
        meta: {
          title: "워치 등록",
          logo: "device-qr-icon.png",
        },
        component: () => import("@/views/device/deviceAdd.vue"),
      },
      {
        path: "/device/mydevice",
        name: "MyDevice",
        meta: {
          title: "워치 관리",
          logo: "device-qr-icon.png",
        },
        component: () => import("@/views/device/mydevice.vue"),
      },
      {
        path: "/device/deviceUpdate/:deviceId/:gatewayId/:pet_code",
        name: "deviceUpdate",
        meta: {
          title: "워치 관리",
          logo: "device-qr-icon.png",
        },
        component: () => import("@/views/device/deviceUpdate.vue"),
      },
    ],
  },
  {
    path: "/gateway",
    name: "Gateway",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/gateway/list",
        name: "GatewayIndex",
        meta: {
          title: "중계기 관리",
          logo: "device-qr-icon.png",
        },
        component: () => import("@/views/gateway/index.vue"),
      },
    ],
  },
  {
    path: "/reservation",
    name: "Reservation",
    component: () => import("@/components/Layout/homeLayout.vue"),
    children: [
      {
        path: "/reservation/reserve",
        name: "Reserve",
        meta: {
          title: "피치바잇 유치원",
          logo: "kinder_nm-icon.png",
        },
        component: () => import("@/views/reservation/reserve.vue"),
      },
      {
        path: "/reservation/kinder-info-nm",
        name: "KinderInfo",
        meta: {
          title: "피치바잇 유치원",
          logo: "kinder_nm-icon.png",
        },
        component: () => import("@/views/reservation/kinder-info-nm.vue"),
      },
      {
        path: "/reservation/find-school",
        name: "FindSchool",
        meta: {
          title: "피치바잇 유치원",
          logo: "kinder_nm-icon.png",
        },
        component: () => import("@/views/reservation/find-school.vue"),
      },
    ],
  },
  {
    path: "/wizard",
    name: "Wizard",
    component: () => import("@/components/Layout/wizardLayout.vue"),
    children: [
      {
        path: "/wizard/wizard_step1_1",
        name: "wizardStep1_1",
        component: () => import("@/views/wizard/pet_wizard1.vue"),
      },
      {
        path: "/wizard/wizard_step1_2",
        name: "wizardStep1_2",
        component: () => import("@/views/wizard/pet_wizard2.vue"),
      },
      {
        path: "/wizard/wizard_step3/:petCode",
        name: "wizardStep1_3",
        props: true,
        component: () => import("@/views/wizard/pet_wizard3.vue"),
      },
      {
        path: "/wizard/wizard_step2_1",
        name: "wizardStep2_1",
        component: () => import("@/views/wizard/wizard2_1.vue"),
      },
      {
        path: "/wizard/wizard_step2_2",
        name: "wizardStep2_2",
        component: () => import("@/views/wizard/wizard2_2.vue"),
      },
      {
        path: "/wizard/wizard_step3_1",
        name: "wizardStep3_1",
        component: () => import("@/views/wizard/wizard3_1.vue"),
      },
      {
        path: "/wizard/wizard_step3_2",
        name: "wizardStep3_2",
        component: () => import("@/views/wizard/wizard3_2.vue"),
      },
      {
        path: "/wizard/wizard_step3_3/:petCode",
        name: "wizardStep3_3",
        component: () => import("@/views/wizard/wizard3_3.vue"),
      },
      {
        path: "/wizard/wizard_step3_4",
        name: "wizardStep3_4",
        component: () => import("@/views/wizard/wizard3_4.vue"),
      },
      {
        path: "/wizard/wizard_step3_5",
        name: "wizardStep3_5",
        component: () => import("@/views/wizard/wizard3_5.vue"),
      },
      {
        path: "/wizard/wizard_step3_6",
        name: "wizardStep3_6",
        component: () => import("@/views/wizard/wizard3_6.vue"),
      },
      {
        path: "/wizard/wizard_step3_7",
        name: "wizardStep3_7",
        component: () => import("@/views/wizard/wizard3_7.vue"),
      },
      {
        path: "/wizard/wizard_step3_8",
        name: "wizardStep3_8",
        component: () => import("@/views/wizard/wizard3_8.vue"),
      },
      {
        path: "/wizard/wizard_step3_9",
        name: "wizardStep3_9",
        component: () => import("@/views/wizard/wizard3_9.vue"),
      },
      {
        path: "/wizard/wizard_step3_10",
        name: "wizardStep3_10",
        component: () => import("@/views/wizard/wizard3_10.vue"),
      },
      {
        path: "/wizard/walk_wizard1_1",
        name: "walkWizard1_1",
        component: () => import("@/views/wizard/walk_wizard1_1.vue"),
      },
      {
        path: "/wizard/walk_wizard1_2",
        name: "walkWizard1_2",
        component: () => import("@/views/wizard/walk_wizard1_2.vue"),
      },
      {
        path: "/wizard/walk_wizard1_3",
        name: "walkWizard1_3",
        component: () => import("@/views/wizard/walk_wizard1_3.vue"),
      },
      {
        path: "/wizard/walk_wizard1_4",
        name: "walkWizard1_4",
        component: () => import("@/views/wizard/walk_wizard1_4.vue"),
      },
      {
        path: "/wizard/walk_wizard1_5",
        name: "walkWizard1_5",
        component: () => import("@/views/wizard/walk_wizard1_5.vue"),
      },
      {
        path: "/wizard/walk_wizard1_6",
        name: "walkWizard1_6",
        component: () => import("@/views/wizard/walk_wizard1_6.vue"),
      },
      {
        path: "/wizard/walk_wizard1_7",
        name: "walkWizard1_7",
        component: () => import("@/views/wizard/walk_wizard1_7.vue"),
      },
      {
        path: "/wizard/walk_wizard1_8",
        name: "walkWizard1_8",
        component: () => import("@/views/wizard/walk_wizard1_8.vue"),
      },
      {
        path: "/wizard/walk_wizard1_9",
        name: "walkWizard1_9",
        component: () => import("@/views/wizard/walk_wizard1_9.vue"),
      },
      {
        path: "/wizard/walk_wizard2_1",
        name: "walkWizard2_1",
        component: () => import("@/views/wizard/walk_wizard2_1.vue"),
      },
      {
        path: "/wizard/walk_wizard2_2",
        name: "walkWizard2_2",
        component: () => import("@/views/wizard/walk_wizard2_2.vue"),
      },
      {
        path: "/wizard/walk_wizard2_3",
        name: "walkWizard2_3",
        component: () => import("@/views/wizard/walk_wizard2_3.vue"),
      },
      {
        path: "/wizard/walk_wizard2_4",
        name: "walkWizard2_4",
        component: () => import("@/views/wizard/walk_wizard2_4.vue"),
      },
      {
        path: "/wizard/walk_wizard2_5",
        name: "walkWizard2_5",
        component: () => import("@/views/wizard/walk_wizard2_5.vue"),
      },
      {
        path: "/wizard/walk_wizard2_6",
        name: "walkWizard2_6",
        component: () => import("@/views/wizard/walk_wizard2_6.vue"),
      },
      {
        path: "/wizard/walk_wizard2_7",
        name: "walkWizard2_7",
        component: () => import("@/views/wizard/walk_wizard2_7.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!Vue.$cookies.get("accessToken");
  if (
    to.name !== "LoginIndex" &&
    to.name !== "Register" &&
    to.name !== "Register2" &&
    to.name !== "Register3" &&
    to.name !== "FindID" &&
    to.name !== "FindPW" &&
    !isAuthenticated
  ) {
    alert("로그인 후 이용해주세요.");
    next({ name: "LoginIndex" });
  } else {
    next();
  }
});

export default router;
