import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ko"; // 한국어 언어 팩
import router from "@/router";
import VueApexCharts from "vue-apexcharts";
import { Bar, Line } from "vue-chartjs";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import vueCookies from "vue-cookies";
import axios from "axios";
import VueQrcodeReader from "vue-qrcode-reader";
import { Buffer } from "buffer";
import process from "process";
window.process = process;
window.Buffer = Buffer;

router.beforeEach((to, from, next) => {
  if (to.path !== "/next-page" && !navigator.onLine) {
    // 인터넷 연결이 없는 경우, 페이지 이동 취소
    next(false);
    console.log("인터넷 연결이 없습니다.");
  } else {
    // 인터넷 연결이 있는 경우, 페이지 이동 허용
    next();
  }
});

Vue.config.productionTip = false;

Chart.register(CategoryScale);

Vue.component("bar-chart", Bar);
Vue.component("line-chart", Line);
Vue.use(VueQrcodeReader);

import("./assets/css/default.css");
import("./assets/css/hyh.css");
import("./assets/css/yymim.css");

Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.use(vueCookies);

Vue.prototype.$meta = {};

// Axios를 Vue 인스턴스에 글로벌로 설정
Vue.prototype.$axios = axios.create({
  baseURL: "https://api.peachbite.co.kr",
});

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (
      to.name !== "LoginIndex" &&
      to.name !== "Register" &&
      to.name !== "Register2" &&
      to.name !== "Register3" &&
      to.name !== "FindID" &&
      to.name !== "FindPW" &&
      to.name !== undefined
    ) {
      const expireDate = new Date(
        decodeURIComponent(Vue.$cookies.get("expire_date"))
      );
      const currentDate = new Date(); // 현재 시간
      const expireDateTime = new Date(expireDate); // 세션 만료 시간

      if (currentDate > expireDateTime) {
        alert("로그인 세션이 만료되어 로그아웃 됩니다.");
        Vue.$cookies.remove("token_type");
        Vue.$cookies.remove("accessToken");
        Vue.$cookies.remove("expire_date");
        Vue.$cookies.remove("keepLogin");
        Vue.$cookies.remove("petData");
        Vue.$cookies.remove("user_id");
        Vue.$cookies.remove("user_type");
        Vue.$cookies.remove("initialIndex");
        Vue.$cookies.remove("walkPet");
        next((vm) => {
          vm.$router.push({ name: "LoginIndex" });
        });
      } else {
        next();
      }
    } else {
      next();
    }
  },
});
new Vue({
  store,
  router,
  render: (h) => h(App),

  el: "#app",
  components: {
    apexchart: VueApexCharts,
  },
  data: {
    series: [44, 55, 41, 17, 15],
    chartOptions: {
      chart: {
        width: 380,
        type: "donut",
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  },
  mounted() {
    this.setVh();
    window.addEventListener("resize", this.setVh);
  },
  methods: {
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    logout() {
      const expireDate = new Date(
        decodeURIComponent(Vue.$cookies.get("expire_date"))
      );
      const currentDate = new Date(); // 현재 시간
      const expireDateTime = new Date(expireDate); // 세션 만료 시간

      if (currentDate > expireDateTime) {
        Vue.$cookies.remove("token_type");
        Vue.$cookies.remove("accessToken");
        Vue.$cookies.remove("expire_date");
        Vue.$cookies.remove("keepLogin");
        Vue.$cookies.remove("petData");
        Vue.$cookies.remove("user_id");
        Vue.$cookies.remove("user_type");
        Vue.$cookies.remove("initialIndex");
        Vue.$cookies.remove("walkPet");
        Vue.$router.push({ name: "LoginIndex" });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setVh);
  },
}).$mount("#app");
